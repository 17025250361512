import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import useImageSrc from '../hooks/useImageSrc';

// Utility function to convert plain text URLs into clickable links
function linkify(inputText) {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return inputText.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
}

const Notification = ({
  notification,
  readStyle,
  unreadStyle,
  notificationReadHandler,
}) => {
  const [notificationImageDialogOpen, setNotificationImageDialogOpen] =
    useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const imageSrc = useImageSrc(
    userInfo?.token ?? '',
    notification?.image ?? ''
  );

  const linkedContent = linkify(notification.content);
  const safeHTML = DOMPurify.sanitize(linkedContent);

  return (
    <Grid item xs={12} style={notification.read ? readStyle : unreadStyle}>
      <Dialog
        onClose={() => setNotificationImageDialogOpen(false)}
        open={notificationImageDialogOpen}
      >
        <IconButton
          aria-label="close"
          onClick={() => setNotificationImageDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <CloseIcon />
        </IconButton>
        <img src={imageSrc} alt="" />
      </Dialog>
      <Grid container spacing={2}>
        {notification.image !== null && (
          <Grid
            item
            xs={12}
            md={4}
            order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>
              <img
                src={imageSrc}
                alt={notification.content}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  cursor: 'pointer',
                  borderRadius: '15px',
                }}
                onClick={() => setNotificationImageDialogOpen(true)}
              />
            </div>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={notification.image !== null ? 8 : 12}
          order={{ xs: 2, sm: 2, md: 1, lg: 1 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div>
            <Typography variant="h6" gutterBottom>
              <span
                dangerouslySetInnerHTML={{
                  __html: safeHTML,
                }}
              />
            </Typography>
            <Typography variant="p" gutterBottom style={{ display: 'block' }}>
              {format(new Date(notification?.created), 'MMMM dd, yyyy')}
            </Typography>
            {notification.read ? null : (
              <Button
                size="small"
                variant="outlined"
                disableElevation
                style={{ marginTop: '20px' }}
                onClick={() => notificationReadHandler(notification.id)}
              >
                Mark as read
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notification;
